@import "../../Assets/StylesTemplate/colors";
@import "../../Assets/StylesTemplate/mixins";

.sidebar {
   position: fixed;
   left: 0;
   top: 80px;
   width: 250px;
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   background-color: $darkGrey;
   z-index: 49;
   transition: all 0.3s ease-in-out;

   @media screen and (max-width: 1024px) {
      width: 150px;
   }
   @media screen and (max-width: 768px) {
      top: 60px;
      right: calc(100% - 1rem);
      width: 200px;
      opacity: 0;
      pointer-events: none;
      transform: translateX(-150px);
   }
}

.activeSidebar {
   opacity: 1;
   transform: translateX(0);
   pointer-events: auto;
}

.linkItem {
   display: flex;
   align-items: center;
   padding: 1rem 1rem 1rem 2rem;
   color: #ffffff;
   text-decoration: none;
   font-size: 20px;
   font-weight: 500;
   cursor: pointer;

   &:hover {
      @include magentaGradientBackground;
      color: #ffffff;
   }

   @media screen and (max-width: 1024px) {
      padding: 1rem;
      font-size: 18px;
   }

   svg {
      font-size: 25px;
      margin-right: 0.5rem;

      @media screen and (max-width: 1024px) {
         display: none;
      }
   }
}

.activeLink {
   @extend .linkItem;
   @include magentaGradientBackground;
}
