@import "./colors";
@import "./mixins";

// font sizes__________________________________________
.size-12 {
   font-size: 12px;
}
.size-14 {
   font-size: 14px;
}
.size-16 {
   font-size: 16px;
}
.size-18 {
   font-size: 18px;
}
.size-20 {
   font-size: 20px;
}
.size-24 {
   font-size: 24px;
}
.size-26 {
   font-size: 26px;
}
.size-30 {
   font-size: 30px;

   @media screen and (max-width: 768px) {
      font-size: 28px;
   }
   @media screen and (max-width: 600px) {
      font-size: 26px;
   }
}
.size-35 {
   font-size: 35px;
}
.size-40 {
   font-size: 40px;

   @media screen and (max-width: 450px) {
      font-size: 30px;
   }
}
.size-64 {
   font-size: 64px;
}

// font styles __________________________________________

.novaBold900 {
   font-weight: 900;
   font-style: normal;
}

.novaBold700 {
   font-weight: 700;
   font-style: normal;
}

.novaMedium {
   font-weight: 500;
   font-style: normal;
}

.novaRegular {
   font-weight: 400;
   font-style: normal;
}

.novaLight {
   font-weight: 300;
   font-style: normal;
}

// font colors________________________________________________________
.textMagentaGradient {
   @include magentaTextGradient;
}

.magentaText {
   color: $primaryMagenta;
}

.darkText {
   color: $darkGrey;
}

.whiteText {
   color: $whiteColor;
}

.highlightSpan {
   color: $darkGrey;

   &:hover {
      text-decoration: underline;
      color: $primaryMagenta;
   }
}
