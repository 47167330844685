@import "../../Assets/StylesTemplate/colors";
@import "../../Assets/StylesTemplate/mixins";

.mainHeader {
   position: sticky;
   top: 0;
   width: 100%;
   height: 80px;
   background-color: #ffffff;
   z-index: 50;

   @media screen and (max-width: 768px) {
      height: 60px;
   }
}

.headerIcon {
   font-size: 40px;
   margin-right: 0.5rem;
   color: $darkGrey;

   @media screen and (max-width: 1024px) {
      font-size: 30px;
   }
   @media screen and (max-width: 768px) {
      font-size: 25px;
   }
   @media screen and (max-width: 500px) {
      font-size: 20px;
   }
}

.heading {
   font-size: 40px;

   @media screen and (max-width: 1024px) {
      font-size: 30px;
   }
   @media screen and (max-width: 768px) {
      font-size: 25px;
   }
   @media screen and (max-width: 500px) {
      font-size: 20px;
   }
}

.controlsContainer {
   position: relative;
   width: 100%;
   max-width: 320px;
   display: flex;
   align-items: center;
   justify-content: flex-end;
}

.userIcon {
   background-color: $darkGrey;
   width: 50px;
   height: 50px;
   border-radius: 50%;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;

   & > svg {
      font-size: 30px;
      color: $whiteColor;
   }

   @media screen and (max-width: 1024px) {
      width: 45px;
      height: 45px;
   }
   @media screen and (max-width: 768px) {
      width: 40px;
      height: 40px;

      & > svg {
         font-size: 25px;
      }
   }
   @media screen and (max-width: 500px) {
      width: 30px;
      height: 30px;

      & > svg {
         font-size: 20px;
      }
   }
}

.userDropdown {
   position: absolute;
   right: 0;
   top: calc(100% + 1rem);
   width: 100%;
   max-width: 320px;
   height: 200px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   border-radius: 16px;
   padding: 1.5rem;
   background-color: #ffffff;
   box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
   opacity: 0;
   pointer-events: none;
   transform: translateY(-20px);
   transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
   cursor: auto;

   @media screen and (max-width: 500px) {
      max-width: 500px;
   }
   @media screen and (max-width: 400px) {
      h5 {
         font-size: 18px;
      }

      span {
         font-size: 14px;
      }
   }
}

.dropMenuActive {
   opacity: 1;
   transform: translateY(0);
   pointer-events: auto;
}

.logoutButton {
   @include magentaButton(120px, 40px);
   margin-top: 1.5rem;
}

.menuIcon {
   margin-left: 1.5rem;
   font-size: 1.2rem;
   cursor: pointer;

   @media screen and (min-width: 769px) {
      display: none;
   }
}
