@import "./colors";
@import "./mixins";
@import "./tipography";
@import "./containers";
// @import "~bootstrap/scss/bootstrap";

html,
#root,
body,
.layout {
   width: 100%;
   height: 100%;
}

* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}

body {
   font-family: "proxima-nova", sans-serif;
   color: $darkGrey !important;
}

.pointer {
   cursor: pointer;
}

input {
   font-family: "proxima-nova", sans-serif !important;

   &::placeholder {
      font-family: "proxima-nova", sans-serif !important;
   }
}

// Bootstrap Modal__________________________________________________
.modal-header {
   border-bottom: none !important;
}

.btn-close {
   position: absolute;
   top: 20px;
   right: 20px;
}

// input type checkbox_____________________________________________
input[type=checkbox] {
   position: relative;
    cursor: pointer;
}
input[type=checkbox]:before {
   content: "";
   display: block;
   position: absolute;
   width: 16px;
   height: 16px;
   top: 0;
   left: 0;
   border: 2px solid #555555;
   border-radius: 3px;
   background-color: white;
}
input[type=checkbox]:checked:after {
   content: "";
   display: block;
   width: 5px;
   height: 10px;
   border: solid black;
   border-width: 0 2px 2px 0;
   -webkit-transform: rotate(45deg);
   -ms-transform: rotate(45deg);
   transform: rotate(45deg);
   position: absolute;
   top: 2px;
   left: 6px;
}

// inactive nav link color___________________________________________
.nav-item {
   .nav-link, 
   .nav-link:focus,
   .nav-link:hover {
      color: $primaryMagenta;
   }
}
