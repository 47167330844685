@import "../../Assets/StylesTemplate/colors";
@import "../../Assets/StylesTemplate/mixins";

.jsonContainer {
   width: 100%;
   height: 700px;
   overflow: auto;
   border-radius: 8px;
   background-color: $darkGrey;
   padding: 1.5rem;
}

.copyButton {
   @include magentaButton(150px, 40px);
}
