// global colors
$primaryMagenta: #c702ff;

$primaryBlue: #225dff;

$darkGrey: #1d1d1f;

$whiteColor: #ffffff;

// global shadows
.bottomShadow {
   box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
