@import "../../Assets/StylesTemplate/mixins";
@import "../../Assets/StylesTemplate/colors";

.onboarding {
   width: 100%;
   height: 100vh;
   background-image: linear-gradient(
      45deg,
      hsl(287deg 100% 50%) 0%,
      hsl(286deg 85% 48%) 11%,
      hsl(285deg 77% 43%) 22%,
      hsl(284deg 71% 38%) 33%,
      hsl(283deg 66% 32%) 44%,
      hsl(282deg 60% 27%) 56%,
      hsl(281deg 54% 21%) 67%,
      hsl(279deg 47% 16%) 78%,
      hsl(273deg 39% 10%) 89%,
      hsl(224deg 100% 2%) 100%
   );
}

.formContainer {
   width: 100%;
   max-width: 500px;
   min-height: 450px;
   padding: 2rem;
   background-color: rgba(255, 255, 255, 0.7);
   border-radius: 16px;

   @media screen and (max-width: 450px) {
      padding: 1rem;
   }
}

.inputLabel {
   width: 100%;
   margin-bottom: 1.5rem;
}

.inputContainer {
   position: relative;
   width: 100%;
}

.loginInput {
   width: 100%;
   padding: 1rem;
   outline: none;
   border: none;
   border-radius: 8px;
   background-color: #ffffff;
   color: $darkGrey;
   font-size: 18px;

   @media screen and (max-width: 450px) {
      padding: 0.7rem;
   }
}

.mainActionButton {
   @include gradientMagentaButton(100%, 60px);

   @media screen and (max-width: 450px) {
      height: 50px;
   }
}

.inputIcons {
   position: absolute;
   right: 20px;
   top: 50%;
   transform: translateY(-50%);
   font-size: 25px;
   color: $primaryMagenta;
   cursor: pointer;

   @media screen and (max-width: 500px) {
      font-size: 18px;
   }
}
