@import "../../../Assets/StylesTemplate/colors";
@import "../../../Assets/StylesTemplate/mixins";

.tokenForm {
   width: 100%;
   max-width: 700px;
   display: flex;
   flex-direction: column;
   background-color: #ffffff;
   border-radius: 8px;
   padding: 1.5rem;
   margin-bottom: 1rem;
   box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.inputLabel {
   width: 100%;
   display: flex;
   flex-direction: column;
}

.tokenInputs {
   width: 100%;
   padding: 1rem;
   font-size: 18px;
   border-radius: 8px;
   border: none;
   outline: none;
   box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.mainActionButton {
   @include gradientMagentaButton(100%, 60px);
   max-width: 200px;
   height: 35px;
   font-size: 16px;
 }
 
.copyButton {
   @include magentaButton(150px, 40px);
}
