@import "../../Assets/StylesTemplate/mixins";

.errorPage {
   width: 100%;
   height: 100vh;
}

.errorImage {
   width: 100%;
   max-width: 400px;
   height: auto;
   margin-bottom: 2rem;
}

.backButton {
   @include gradientMagentaButton(150px, 60px);
}
