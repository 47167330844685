@import "../../../Assets/StylesTemplate/mixins";
@import "../../../Assets/StylesTemplate/colors";

.enterprisePage {
   min-height: 100vh;
   display: flex;
   align-items: center;
   justify-content: center;

   & > div {
      @include hidrBox;
      min-height: 600px;
      max-width: 800px;
   }
}

.enterpriseBox {
   width: 100%;
   width: 150px;
   height: 150px;
   padding: 0.5rem;
   background-image: linear-gradient(
      45deg,
      hsl(287deg 100% 50%) 0%,
      hsl(286deg 85% 48%) 11%,
      hsl(285deg 77% 43%) 22%,
      hsl(284deg 71% 38%) 33%,
      hsl(283deg 66% 32%) 44%,
      hsl(282deg 60% 27%) 56%,
      hsl(281deg 54% 21%) 67%,
      hsl(279deg 47% 16%) 78%,
      hsl(273deg 39% 10%) 89%,
      hsl(224deg 100% 2%) 100%
   );
   border-radius: 16px;

   &:hover {
      cursor: pointer;
   }
}

.avatar {
   width: 50px;
   height: 50px;
   border-radius: 50%;
   background-color: $whiteColor;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-bottom: 0.5rem;

   & > p {
      @include magentaTextGradient;
   }
}
