@import "./colors";

// buttons
@mixin magentaButton($width, $height) {
   width: $width;
   height: $height;
   border: none;
   outline: none;
   border-radius: 8px;
   background-color: $primaryMagenta;
   color: #ffffff;
   font-size: 20px;
   font-weight: 500;
}

@mixin gradientMagentaButton($width, $height) {
   width: $width;
   height: $height;
   border: none;
   outline: none;
   border-radius: 8px;
   background-image: linear-gradient(to right, $darkGrey, $primaryMagenta);
   color: #ffffff;
   font-size: 20px;
   font-weight: 500;
}

// gradient texts
@mixin magentaTextGradient {
   background-color: $primaryMagenta;
   background-image: linear-gradient(to right, $darkGrey, $primaryMagenta);
   background-size: 100%;
   background-repeat: repeat;

   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   -moz-background-clip: text;
   -moz-text-fill-color: transparent;
}

// background
@mixin magentaGradientBackground {
   background-image: linear-gradient(to right, $darkGrey, $primaryMagenta);
}

@mixin hidrBox {
   width: 100%;
   background-color: #ffffff;
   border-radius: 8px;
   padding: 1.5rem;
   margin-bottom: 1rem;
   box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}