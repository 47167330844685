.mainContainer {
   width: 100%;
   max-width: 1600px;
   padding-left: 1rem;
   padding-right: 1rem;
   margin: auto;
}

.pageContentContainer {
   margin-top: 1rem;
   margin-left: 250px;
   padding: 0 2rem;

   @media screen and (max-width: 1024px) {
      padding: 0 1rem;
      margin-left: 150px;
   }

   @media screen and (max-width: 768px) {
      margin-left: 0;
   }
}

.headerContainer {
   width: 100%;
   padding-left: 2rem;
   padding-right: 2rem;
   margin: auto;

   @media screen and (max-width: 1024px) {
      padding-left: 1rem;
      padding-right: 1rem;
   }
}
