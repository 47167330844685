@import "../../../../Assets/StylesTemplate/colors";

.noBackground {
   width: 100%;
   max-width: 130px;
   height: auto;
}

.settingIcon {
   font-size: 25px;
}

.close {
   color: red;
}

.checked {
   color: green;
}